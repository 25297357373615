<template>
  <div>
    <div class="ui-type-heading text-right">
      {{ getTotalPrice() }}
      <span class="ml-2 text-warning" v-if="needPriceCorrection()">
        <font-awesome-icon icon="balance-scale" />
      </span>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../helpers/common";

export default {
  name: "MarketCartTableVariantTotal",

  computed: {
    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getTotalPrice() {
      const price = get(this.data, `view.tot`, null);
      return price !== null
        ? formatPrice(price, this.currencySymbol).format()
        : "";
    },

    needPriceCorrection() {
      return (
        get(this.data, "view.mp.ppl.pw", false) ||
        get(this.data, "view.mp.ppl.productproducer.pw", false)
      );
    },
  },
};
</script>
